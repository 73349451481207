class PdfRender
  @setScale: (page, width) ->
    @scale = width / page.getViewport(1.0).width

  # Builds canvas element for rendering a PDF page
  @buildCanvas: (page, width, num) ->
    @setScale(page, width)

    $el = $('<canvas/>')
    $el[0].width = width
    $el[0].height = page.getViewport(@scale).height

    $el

  # Builds rendering context for pdf.js
  @getPageRenderContext: ($el, page) ->
    canvasContext: $el[0].getContext('2d')
    viewport: page.getViewport(@scale)

  @constructor: (@file) ->
    @pageCount = @file.numPages
    @pagesRendered = 1
    @$fileContainer = $('<div/>')
    @promise = new $.Deferred()

  @addPage: (page) ->
    $el = @buildCanvas(page, 700, @pagesRendered)
    @$fileContainer.append($el)
    page.render(@getPageRenderContext($el, page))

  @addForPrint: ->
    if @pagesRendered <= @pageCount
      @file.getPage(@pagesRendered).then (page) =>
        @addPage(page).then =>
          @pagesRendered++
          @promise.resolve(@$fileContainer) if @pagesRendered > @pageCount
          @addForPrint()

  @positionFields: (page) ->
    scale = @scale
    viewport = page.getViewport(scale)
    _.each $(".page#{page.pageNumber} .onboarding-form-field"), (field) ->
      $field = $(field)
      position = $field.position()
      point = viewport.convertToViewportPoint(position.left, position.top)
      $field.css({
        height: parseInt($field.css('height')) * scale + 'px'
        width: parseInt($field.css('width')) * scale + 'px'
        top: point[1] + 'px'
        left: point[0] + 'px'
      })

  @setupAnnotations: (page, viewport, canvas) ->
    annotationClass = "annotationLayer annotations-#{@currentPageNumber}"
    $annotationLayerDiv = $ '<div />', class: annotationClass
    $(canvas).parent().append $annotationLayerDiv

    promise = page.getAnnotations().then (annotationsData) ->
      viewport = viewport.clone
        dontFlip: true

      _.each annotationsData, (data) ->
        return unless data && data.hasHtml
        return if data.subtype == 'Link' && !data.url

        element = PDFJS.AnnotationUtils.getHtmlElement(data, page.commonObjs)
        $(element).find('a').prop('target', '_blank')

        rect = data.rect
        view = page.view
        rect = PDFJS.Util.normalizeRect [
          rect[0],
          view[3] - rect[1] + view[1],
          rect[2],
          view[3] - rect[3] + view[1]
        ]
        element.style.left = rect[0] + 'px'
        element.style.top = rect[1] + 'px'
        element.style.position = 'absolute'

        transform = viewport.transform
        transformStr = 'matrix(' + transform.join(',') + ')'
        element.style.transform = transformStr
        transformOriginStr = -rect[0] + 'px ' + -rect[1] + 'px'
        element.style.transformOrigin = transformOriginStr

        $annotationLayerDiv.append(element)

    promise

window.PdfRender = PdfRender
