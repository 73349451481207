Pjax = window.Pjax

# Keep track of whether or not the preview is being shown currently to prevent
# trying to show it twice.
window.showingPreview = false

shouldClose = ->
  saving = window.savingPreview
  (typeof saving isnt 'undefined' && !saving) || window.closePreview

class Preview
  MAX_PAGES_INTERVAL: 10
  PROFILE_PJAX_OPTIONS: {
    container: Pjax.targetContainer('profile')
  }

  constructor: (el) ->
    @$container = $(Pjax.targetContainer('preview'))
    @$el = $(el)

    # When saving forms rendered via preview, the new preview should only be
    # re-rendered if there was a form error.
    if shouldClose()
      delete window.savingPreview
      delete window.closePreview
      @close()
      return

    @renderingPage = 0
    @fullyRenderedPdf = false

    @$source = @$el.find('.preview-source')
    @$content = @$el.find('.preview-source-content')
    @$pageContainer = if @$content.find('.page-container').length == 0
      @$content
    else
      @$content.find('.page-container')
    @canSave = @$el.data().pjaxSubmit != undefined
    @fileType = @$el.data('preview-type')

    if @isPending()
      @pollID = @poll()
      @addPendingEvents()

    if @fileType == 'pdf' && url = @url()
      require('../../../public/pdfjs/pdf.js') if typeof(PDFJS) == 'undefined'
      PDFJS.workerSrc = '/pdfjs/pdf.worker.js'
      PDFJS.disableCreateObjectURL = true

      @loadPdf(url)
    else if !@isPending()
      @addEvents()

  @selector: (container) ->
    "[data-preview='#{container}']"

  addEvents: ->
    $('body').on('keyup', @closeForEscape)
    @$el.find('[data-action=close]').on('click', @close)
    $('[data-pjax-container=preview]').on('pjax:start', @startSave)
    $('[data-pjax-container=preview]').on('pjax:end', @afterSave)
    @$el.trigger('preview:open', @)

  addPendingEvents: =>
    $('body').on 'keyup', (e) ->
      window.closePreview = true if Keyboard.keyMatchesName e.which, 'esc'

    @$el.find('[data-action=close]').on 'click', ->
      window.closePreview = true

  closeForEscape: (e) =>
    @close() if Keyboard.keyMatchesName e.which, 'esc'

  reloadMainContent: (saveEvent) ->
    if saveEvent
      if window.location.href.indexOf('onboarding/required_documents') > 0
        pjax_options = $.extend({
          'url': window.location.href.replace('/required_documents', '')
        }, @PROFILE_PJAX_OPTIONS)
        return $.pjax(pjax_options)
      else
        return $.pjax.reload(@PROFILE_PJAX_OPTIONS)
    else
      return $.Deferred().resolve()

  close: (e, saveEvent = false) =>
    e?.preventDefault()
    window.showingPreview = false
    clearTimeout(@pollID)
    @$container.off()

    previewContainer = $('[data-preview]')

    $.when(@reloadMainContent(saveEvent)).done ->
      previewContainer.fadeOut ->
        previewContainer.remove()
        if saveEvent
          pjax_options = { container: Pjax.targetContainer('profile') }
          if window.location.href.indexOf('onboarding/required_documents') > 0
            pjax_options['url'] =
              window.location.href.replace('/required_documents', '')
            $.pjax(pjax_options)
          else
            $.pjax.reload(pjax_options)

  startSave: (e, _xhr, options) =>
    return unless $(options.target).is('#new_person_document')
    $button = @$el.find('[type=submit]')
    ButtonHelpers.setButtonState($button, 'disabled') if !!$button[0]
    window.savingPreview = true

  afterSave: (e, _contents, options) =>
    return unless $(options.target).is('#new_person_document')
    if @$container.find('.form-error-messages').length == 0
      window.savingPreview = false
      # Also close all modals that might be open behind the preview
      if window.currentModal != undefined
        window.currentModal.close()
      @close(e, true)
    else
      delete window.savingPreview

  isPending: ->
    @$content.find('[data-status=pending]').length > 0

  poll: (time = 1500) =>
    setTimeout(@reload, time)

  reload: =>
    @$el.find('[data-pjax-click=preview]').click()

  pdfRendered: =>
    @addSaveEvents() if @canSave
    @$el.trigger('preview:open')

  loadPdf: ->
    url = @$el.find('[data-preview-url]').attr('href')
    return $.Deferred().resolve() unless url

    pdfDoc = PDFJS.getDocument(url, undefined, =>
      console.error('PDF file is password protected')
      @addEvents()
    )
    pdfDoc.then(@pdfLoaded).then(@pdfRendered)

  url: ->
    @pdfUrl ||= @$el.find('[data-preview-url]').attr('href')

  addSaveEvents: ->
    @$container.on('pjax:start', @startSave)
    @$container.on('pjax:beforeReplace', @afterSave)

  pdfLoaded: (pdf) =>
    @pdf = pdf
    @pageCount = pdf.numPages

    @maxPagesToShow = @MAX_PAGES_INTERVAL # Adjusted on scroll
    @$source.scroll @scrollSpy
    @renderPdf()

  renderPdf: =>
    if @$pageContainer.find('[data-status=pdf_fields_loading]').length > 0
      @pollFields()
    else
      @pdfLoadPage()
      @addEvents()

  pollFields: (time = 500) =>
    setTimeout(@renderPdf, time)

  # Spys on the scroll, and at 85% scrolled down begins loading more pages
  scrollSpy: (e) =>
    return if @fullyRenderedPdf

    scroll = @$source.scrollTop()
    height = @$content[0].scrollHeight - $(window).height()
    scrollPercentage = scroll / height * 100

    if scrollPercentage >= 85
      newMax = @maxPagesToShow + @MAX_PAGES_INTERVAL
      @maxPagesToShow = Math.min newMax, @pageCount
      @pdfLoadPage @currentPageNumber + 1

  pdfLoadPage: (num = 1) =>
    return if num > @pageCount || num > @maxPagesToShow

    currentPage = @pdf.getPage num
    currentPage.then @renderPage

  renderPage: (page) =>
    @currentPageNumber = page.pageNumber
    return if @renderingPage >= @currentPageNumber

    @renderingPage = @currentPageNumber
    @fullyRenderedPdf = true if @currentPageNumber is @pageCount

    # TODO: Make this adjust wider on resize of window
    canvasWidth = 975
    scale = canvasWidth / page.getViewport(1.0).width
    viewport = page.getViewport(scale)
    $canvas = PdfRender.buildCanvas(page, canvasWidth, @currentPageNumber)
    $canvas.attr('id', "page#{@currentPageNumber}")

    if @$pageContainer.find('.page.page' + page.pageNumber).length == 0
      options = { class: 'page page' + page.pageNumber }
      @$pageContainer.append $('<div/>', options)

    $page = @$pageContainer.find('.page' + page.pageNumber)

    if @$pageContainer.find('[data-pjax-submit=pdf_fields]').length == 1 &&
       $page.find('[data-pjax-container]').length == 0
      options = { 'data-pjax-container': 'pdf_fields_' + page.pageNumber }
      $page.append $('<div/>', options)

    $page.append $canvas
    $page.data('viewport', viewport)

    renderContext = PdfRender.getPageRenderContext $canvas, page

    page.render(renderContext).then =>
      PdfRender.positionFields(page)
      PdfRender.setupAnnotations(
        page, renderContext['viewport'], $canvas[0], @currentPageNumber
      )
      @pdfLoadPage @currentPageNumber + 1

    @$el.trigger('preview:renderPage', [@, page])

window.Preview = Preview

$.onmount '[data-preview]', ->
  return if window.showingPreview

  window.showingPreview = true
  new Preview(@)
